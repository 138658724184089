import React from 'react'

import { Whisper } from '../typography'
import BulletSeparator from './BulletSeparator'
import Link from './Link'
import BodySection from './BodySection'
import { makeStyles } from '@material-ui/styles'

import { SearchEngineNames } from '../data'
import packageJson from '../../package.json'

const SITE_VERSION = packageJson.version

const useStyles = makeStyles({
  contactLinks: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 24,
    paddingBottom: 16,
    '& p': {
      textAlign: 'center',
      paddingBottom: 4,
    },
  },
})

const searchEngineNames = SearchEngineNames.join(', ')

const Footer: React.FC = () => {
  const classes = useStyles()

  return (
    <footer>
      <BodySection>
        <Whisper bold>Made by David Wheatley - &copy; {new Date().getFullYear()}</Whisper>
        <Whisper>Run by Bs_zombie</Whisper>
        <Whisper>{searchEngineNames} and LMGTFY are trademarks of their respective owners.</Whisper>
        <Whisper>Website version {SITE_VERSION}</Whisper>
        <Whisper>
          LMGTFY.xyz is{' '}
          <Link target="_blank" url="https://github.com/BS-zombie/LMGTFY">
            Open Source!
          </Link>
        </Whisper>
      </BodySection>
    </footer>
  )
}

export default Footer
